<!--
// nuxt-ui/components/Shared/standings/StandingsLegend.vue
-->
<script setup lang="ts">
import type {
  StandingPlayoffColors,
  StandingPositionColor,
} from "~/src/season-cluster/domain/models/01.Season";

type Props = {
  legend: StandingPlayoffColors[];
  large?: boolean;
  groupedColors?: boolean;
  isHomeLegend?: boolean;
};

const props = withDefaults(defineProps<Props>(), { large: false, groupedColors: false });

const unifiedLegend = computed((): StandingPlayoffColors[] => {
  try {
    return Array.from(new Set(props.legend.map(e => JSON.stringify(e)))).map(e => JSON.parse(e));
  } catch (e) {
    console.error(e);
    return [];
  }
});

function getBgClass(playoffColor: StandingPositionColor) {
  //This function MUST be defined in the Vue file. In any other cases, Tailwind won't render the classes.
  switch (playoffColor) {
    case "green":
      return "bg-emerald-600";
    case "orange":
      return "bg-amber-400";
    case "softOrange":
      return "bg-[#EC7B39]";
    case "red":
      return "bg-red-500";
    case "yellow":
      return "bg-yellow-200";
    case "brown":
      return "bg-[#A8834E]";
    case "gray":
      return "bg-gray-500";
  }
}

function getTextClass(playoffColor: StandingPositionColor) {
  //This function MUST be defined in the Vue file. In any other cases, Tailwind won't render the classes.
  switch (playoffColor) {
    case "green":
      return "text-emerald-600";
    case "orange":
      return "text-amber-400";
    case "softOrange":
      return "text-[#EC7B39]";
    case "red":
      return "text-red-500";
    case "yellow":
      return "text-yellow-200";
    case "brown":
      return "text-[#A8834E]";
    case "gray":
      return "text-gray-500";
  }
}
</script>

<template>
  <div
    v-if="!groupedColors"
    :class="['flex flex-wrap', large ? 'gap-x-8 gap-y-4' : 'gap-x-4 gap-y-2', { '!gap-x-2': isHomeLegend }]"
  >
    <div v-for="el in unifiedLegend" :class="['flex items-center', large ? 'gap-x-3' : 'gap-x-1.5']">
      <div :class="[large ? 'w-1.5 h-6' : 'w-1 h-4', getBgClass(el.color)]"></div>
      <div :class="[{ 'text-xl': large }, isHomeLegend ? 'text-gray-400 text-sm' : getTextClass(el.color)]">
        {{ $t(`tables.legend.placement.${el.placement}`) }}
      </div>
    </div>
  </div>

  <div v-else-if="groupedColors" :class="['flex flex-wrap items-center', large ? 'gap-3' : 'gap-1']">
    <div v-for="el in unifiedLegend">
      <div :class="[large ? 'w-1.5 h-5' : 'w-1 h-4', getBgClass(el.color)]"></div>
    </div>
    <slot />
  </div>
</template>

<style scoped></style>
