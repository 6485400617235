<!--
// nuxt-ui/components/pages/region-world-cup/BracketTablesWorldCup.vue
-->
<script setup lang="ts">
import IconArrowBracketTablesWorldCup from "~/nuxt-ui/components/pages/region-world-cup/IconArrowBracketTablesWorldCup.vue";
import { useSeasons } from "~/nuxt-ui/Shared/composables/season/seasons";
import { useTeams } from "~/nuxt-ui/Shared/composables/teams";
import { useCompetitionStore } from "~/nuxt-ui/stores/competitionStore";
import { arrowsSliderVisibility, isPastDate } from "~/src/Shared/utils";
import type { MatchCenterData } from "~/src/match/domain/models/Match";
import MatchItemBracketTablesNations from "~/nuxt-ui/components/pages/region-nations/MatchItemBracketTablesNations.vue";

type Props = {
  isHomeComponent?: boolean;
};

defineProps<Props>();

const { useFetchParamsSeasonMatchCenterData } = useSeasons();
const { teamArrToObjById } = useTeams();
const { currentSeasonTeams, currentSeason } = useCompetitionStore();
const { locale } = useI18n();

const { path, opts } = useFetchParamsSeasonMatchCenterData(currentSeason.id, locale.value);
const { data, error } = await useFetch(path, opts);

const teamsObj = await teamArrToObjById(currentSeasonTeams);
const bracketData: MatchCenterData[] = data.value ? data.value : [];

const trophyImage = "https://cms-es.kingsleague.pro/wp-content/uploads/2024/12/kwc-nations-trophy.png";

onMounted(() => {
  const tablesContainer: HTMLElement | null = document.getElementById("tablesSlider");
  const bracketButtonLeft: HTMLElement | null = document.getElementById("bracketButtonLeft");
  const bracketButtonRight: HTMLElement | null = document.getElementById("bracketButtonRight");

  if (tablesContainer) {
    let childrenTablesContainerWidth: number = 0;
    const childrenTablesContainer = [...tablesContainer.children];
    childrenTablesContainer.forEach(item => (childrenTablesContainerWidth += item.clientWidth));
    const swingWrapper = tablesContainer.querySelector(".swing");

    const stagesBracket: NodeListOf<HTMLElement> = tablesContainer.querySelectorAll(
      ".round-col-container.active-stage"
    );
    const lastStagePlayed: HTMLElement | null = stagesBracket[stagesBracket.length - 1];
    tablesContainer.scrollLeft = 0;

    if (lastStagePlayed && tablesContainer) {
      tablesContainer.scrollLeft = lastStagePlayed.getBoundingClientRect().x - 80;
    }

    if (swingWrapper) {
      if (
        Math.round(tablesContainer.scrollLeft) ===
        Math.round(tablesContainer.scrollWidth) - Math.round(tablesContainer.clientWidth)
      )
        //if is totally right
        swingWrapper.classList.remove("swing");
    }

    tablesContainer.addEventListener("scroll", () => {
      if (swingWrapper) {
        swingWrapper.classList.remove("swing");
      }
      arrowsSliderVisibility("general", tablesContainer, bracketButtonLeft, bracketButtonRight);
    });
  }
});
</script>

<template>
  <div
    id="tablesSlider"
    class="w-full overflow-x-scroll overflow-y-hidden hide-scrollbar bigsm:px-4"
    :class="{ 'container-reduced': isHomeComponent }"
  >
    <div
      :class="[
        'grid grid-flow-col auto-cols-max',
        { swing: !isHomeComponent },
        isHomeComponent ? 'gap-12' : 'gap-20',
      ]"
      :style="{ 'scale: 0.8': !isHomeComponent }"
    >
      <!--Round 1-->
      <div class="round-col-container">
        <h3 class="round-title text-white">{{ bracketData[0].turnName }}</h3>
        <div class="round-container nations-border-color">
          <div class="flex flex-col items-stretch gap-4">
            <match-item-bracket-tables-nations
              v-for="match in bracketData[0].matches.sort((a, b) =>
                a.metaInformation.matchName.localeCompare(b.metaInformation.matchName)
              )"
              :key="match.id"
              :match="match"
              :teams-obj="teamsObj"
              :reduced-size="!!isHomeComponent"
            ></match-item-bracket-tables-nations>
          </div>
        </div>
      </div>

      <!--Round 2-->
      <div class="round-col-container" :class="{ 'active-stage': isPastDate(bracketData[0].finishDate) }">
        <h3 class="round-title text-white">{{ bracketData[1].turnName }}</h3>
        <div
          class="bg-gradient-to-t from-[#BF8838] to-[#FFD179] rounded-t-lg px-2 py-0.5 uppercase text-[#101F50] font-semibold"
        >
          {{ $t("bracketKwc.groupWinners") }}
        </div>
        <div class="round-container nations-border-color !mb-6 self-stretch">
          <div class="flex flex-col items-stretch gap-4">
            <match-item-bracket-tables-nations
              v-for="match in bracketData[1].matches
                .sort((a, b) => a.metaInformation.matchName.localeCompare(b.metaInformation.matchName))
                .filter(m => m.metaInformation.previousRound === 'winner')"
              :key="match.id"
              :match="match"
              :teams-obj="teamsObj"
              :reduced-size="!!isHomeComponent"
            ></match-item-bracket-tables-nations>
          </div>

          <icon-arrow-bracket-tables-world-cup
            :width-class="isHomeComponent ? 'w-[3rem]' : 'w-[5rem]'"
            bg-color="#FFD179"
            top-position-class="top-1/2 !z-10"
            text-indicator="1-0"
            no-pointer
            indicator-color="text-[#FFD179]"
          ></icon-arrow-bracket-tables-world-cup>
          <icon-arrow-bracket-tables-world-cup
            :width-class="isHomeComponent ? 'w-[3.1rem]' : 'w-[5rem]'"
            bg-color="#3363FF"
            is-gradient-bg="repeating-linear-gradient(90deg, #3363FF, #3363FF 8px, transparent 8px, transparent 16px, #3363FF 14px);"
            top-position-class="bottom-1/4 !z-10"
            text-indicator="1-1"
            right
            no-pointer
            indicator-color="text-[#3363FF]"
          ></icon-arrow-bracket-tables-world-cup>
          <icon-arrow-bracket-tables-world-cup
            :width-class="isHomeComponent ? 'w-[399px]' : 'w-[559px]'"
            bg-color="#FFD179"
            top-position-class="top-16 !z-10"
            text-indicator="2-0"
            no-pointer
            indicator-color="text-[#FFD179]"
            right
          ></icon-arrow-bracket-tables-world-cup>
        </div>

        <div class="bg-white rounded-t-lg px-2 py-0.5 uppercase text-[#101F50] font-semibold">
          {{ $t("bracketKwc.groupLosers") }}
        </div>
        <div class="round-container border-dashed">
          <div class="flex flex-col items-stretch gap-4">
            <match-item-bracket-tables-nations
              v-for="match in bracketData[1].matches
                .sort((a, b) => a.metaInformation.matchName.localeCompare(b.metaInformation.matchName))
                .filter(m => m.metaInformation.previousRound === 'loser')"
              :key="match.id"
              :match="match"
              :teams-obj="teamsObj"
              :reduced-size="!!isHomeComponent"
            ></match-item-bracket-tables-nations>
          </div>

          <icon-arrow-bracket-tables-world-cup
            :width-class="isHomeComponent ? 'w-[3rem]' : 'w-[5rem]'"
            bg-color="#FFFFFF"
            is-gradient-bg="repeating-linear-gradient(90deg, #ffffff, #ffffff 8px, transparent 8px, transparent 16px, #ffffff 14px);"
            top-position-class="top-1/2 !z-10"
            text-indicator="0-1"
            no-pointer
          ></icon-arrow-bracket-tables-world-cup>
          <icon-arrow-bracket-tables-world-cup
            :width-class="isHomeComponent ? 'w-[3rem]' : 'w-[5rem]'"
            bg-color="#FFFFFF"
            is-gradient-bg="repeating-linear-gradient(90deg, #ffffff, #ffffff 8px, transparent 8px, transparent 16px, #ffffff 14px);"
            top-position-class="top-1/4 !z-10"
            text-indicator="1-1"
            right
            no-pointer
          ></icon-arrow-bracket-tables-world-cup>
          <icon-arrow-bracket-tables-world-cup
            :width-class="isHomeComponent ? 'w-24' : 'w-40'"
            bg-color="#FFFFFF"
            is-gradient-bg="repeating-linear-gradient(90deg, #ffffff, #ffffff 8px, transparent 8px, transparent 16px, #ffffff 14px);"
            top-position-class="bottom-5 !z-10"
            text-indicator="0-2"
            right
            no-pointer
          ></icon-arrow-bracket-tables-world-cup>
        </div>
      </div>

      <!--Last Chance-->
      <div
        ref="lastChanceCol"
        class="round-col-container"
        :class="{ 'active-stage': isPastDate(bracketData[1].finishDate) }"
      >
        <h3 class="round-title text-white">Last Chance</h3>
        <div class="round-container border-dashed border-[#3363FF] bg-black -top-">
          <match-item-bracket-tables-nations
            v-for="match in bracketData[2].matches"
            :key="match.id"
            :match="match"
            :teams-obj="teamsObj"
            :reduced-size="!!isHomeComponent"
          ></match-item-bracket-tables-nations>
          <icon-arrow-bracket-tables-world-cup
            :width-class="isHomeComponent ? 'w-[3rem]' : 'w-[5rem]'"
            bg-color="#3363FF"
            is-gradient-bg="repeating-linear-gradient(90deg, #3363FF, #3363FF 8px, transparent 8px, transparent 16px, #3363FF 14px);"
            indicator-color="text-[#3363FF]"
            text-indicator="2-1"
            top-position-class="top-1/2 !z-10"
            no-pointer
            right
          ></icon-arrow-bracket-tables-world-cup>
        </div>

        <div class="relative">
          <icon-arrow-bracket-tables-world-cup
            :width-class="isHomeComponent ? 'w-[149px]' : 'w-[195px]'"
            bg-color="#FFFFFF"
            is-gradient-bg="repeating-linear-gradient(90deg, #3363FF, #3363FF 8px, transparent 8px, transparent 16px, #3363FF 14px);"
            :top-position-class="isHomeComponent ? '-top-[75px] !z-10' : '-top-20'"
            text-indicator="1-2"
            indicator-color="text-[#3363FF]"
            no-pointer
            vertical
          ></icon-arrow-bracket-tables-world-cup>
          <div
            class="self-stretch bg-[#1D42B5] text-lg text-[#101F50] font-semibold flex justify-center items-center p-2 min-w-24"
          >
            OUT
          </div>
        </div>
      </div>

      <!--cuartos-->
      <div
        class="round-col-container mb-[44px]"
        :class="{ 'active-stage': isPastDate(bracketData[2].finishDate) }"
      >
        <h3 class="round-title nations-color">
          {{ bracketData[3].turnName }}
        </h3>

        <div class="round-container nations-border-color !mb-1">
          <match-item-bracket-tables-nations
            v-for="match in bracketData[3].matches.slice(0, 2)"
            :key="match.id"
            :match="match"
            :teams-obj="teamsObj"
            :reduced-size="!!isHomeComponent"
          ></match-item-bracket-tables-nations>
          <icon-arrow-bracket-tables-world-cup
            :width-class="isHomeComponent ? 'w-[40px]' : 'w-[64px]'"
            bg-color="#FFD179"
            :top-position-class="isHomeComponent ? '-top-[21px] !z-10' : '-top-[34px] !z-10'"
            no-pointer
            vertical
          ></icon-arrow-bracket-tables-world-cup>
        </div>

        <div class="round-container nations-border-color !mt-1">
          <match-item-bracket-tables-nations
            v-for="match in bracketData[3].matches.slice(2, 4)"
            :key="match.id"
            :match="match"
            :teams-obj="teamsObj"
            :reduced-size="!!isHomeComponent"
          ></match-item-bracket-tables-nations>
        </div>
      </div>

      <!--semis-->
      <div
        class="round-col-container mb-[44px]"
        :class="{ 'active-stage': isPastDate(bracketData[3].finishDate) }"
      >
        <h3 class="round-title nations-color">{{ bracketData[4].turnName }}</h3>
        <div
          class="bg-gradient-to-t from-[#BF8838] to-[#FFD179] rounded-t-lg px-2 py-0.5 uppercase text-[#101F50] font-semibold mt-auto"
        >
          Semifinal 1
        </div>
        <div class="round-container nations-border-color !mb-10 !mt-0">
          <match-item-bracket-tables-nations
            :match="bracketData[4].matches[0]"
            :teams-obj="teamsObj"
            :reduced-size="!!isHomeComponent"
          ></match-item-bracket-tables-nations>
          <icon-arrow-bracket-tables-world-cup
            :width-class="isHomeComponent ? 'w-[3rem]' : 'w-[5rem]'"
            bg-color="#FFD179"
            indicator-color="text-[#3363FF]"
            top-position-class="top-1/2 !z-10"
            no-pointer
          ></icon-arrow-bracket-tables-world-cup>
          <icon-arrow-bracket-tables-world-cup
            :width-class="isHomeComponent ? 'w-[1.5rem]' : 'w-[2.5rem]'"
            bg-color="#FFD179"
            top-position-class="top-1/2 !z-10"
            no-pointer
            right
          ></icon-arrow-bracket-tables-world-cup>
        </div>

        <div
          class="bg-gradient-to-t from-[#BF8838] to-[#FFD179] rounded-t-lg px-2 py-0.5 uppercase text-[#101F50] font-semibold"
        >
          Semifinal 2
        </div>
        <div class="round-container nations-border-color !mt-0">
          <match-item-bracket-tables-nations
            :match="bracketData[4].matches[1]"
            :teams-obj="teamsObj"
            :reduced-size="!!isHomeComponent"
          ></match-item-bracket-tables-nations>
          <icon-arrow-bracket-tables-world-cup
            :width-class="isHomeComponent ? 'w-[3rem]' : 'w-[5rem]'"
            bg-color="#FFD179"
            indicator-color="text-[#3363FF]"
            top-position-class="top-1/2 !z-10"
            no-pointer
          ></icon-arrow-bracket-tables-world-cup>
          <icon-arrow-bracket-tables-world-cup
            :width-class="isHomeComponent ? 'w-[1.5rem]' : 'w-[2.5rem]'"
            bg-color="#FFD179"
            top-position-class="top-1/2 !z-10"
            no-pointer
            right
          ></icon-arrow-bracket-tables-world-cup>
        </div>
      </div>

      <!--final-->
      <div
        class="round-col-container mb-[44px]"
        :class="{ 'active-stage': isPastDate(bracketData[4].finishDate) }"
      >
        <h3 class="round-title nations-color">{{ bracketData[5].turnName }}</h3>
        <div class="round-container nations-border-color">
          <img
            :src="trophyImage"
            alt="Trophy Nations"
            width="90"
            height="110"
            class="absolute bottom-[110%] self-center"
          />
          <match-item-bracket-tables-nations
            v-for="match in bracketData[5].matches"
            :key="match.id"
            :match="match"
            :teams-obj="teamsObj"
            :reduced-size="!!isHomeComponent"
          ></match-item-bracket-tables-nations>
          <icon-arrow-bracket-tables-world-cup
            :width-class="isHomeComponent ? 'w-[1.5rem]' : 'w-[2.5rem]'"
            bg-color="#FFD179"
            top-position-class="top-1/2 !z-10"
            no-pointer
          ></icon-arrow-bracket-tables-world-cup>

          <icon-arrow-bracket-tables-world-cup
            :width-class="isHomeComponent ? 'w-[155px]' : 'w-[184px]'"
            bg-color="#FFD179"
            :top-position-class="
              isHomeComponent ? '!-left-[50px] top-[56px] !z-10' : '!-left-[82px] top-[70px] !z-10'
            "
            no-pointer
            vertical-no-centered
          ></icon-arrow-bracket-tables-world-cup>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.round-title {
  @apply font-['Termina'] font-semibold text-xl text-center uppercase mb-6;
}

.round-container {
  @apply border px-5 py-7 flex flex-col items-stretch gap-4 my-auto relative bg-[#1D42B5] bg-opacity-20 rounded-xl;
}

.round-col-container {
  @apply flex flex-col justify-stretch items-center;
}

.swing {
  position: relative;
  animation: swing 5s 5 ease-in-out;
}

@keyframes swing {
  18% {
    transform: translateX(0);
  }
  24% {
    transform: translateX(-20px);
  }
  30% {
    transform: translateX(0);
  }
  36% {
    transform: translateX(-20px);
  }
  42% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(0);
  }
}

.container-reduced {
  @apply my-4;
}

.container-reduced .round-title {
  @apply text-base;
}

.container-reduced .round-container {
  @apply !py-5 !px-3;
}
</style>
